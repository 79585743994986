<template>
    <div class="main-container">
        <!--        {{studentContent}}-->
        <!--        <v-card-->
        <!--                class="mx-auto  pa-5 primary&#45;&#45;text"-->
        <!--                max-width="800"-->
        <!--                elevation="0"-->
        <!--        >-->
        <!--            <span :class="{-->
        <!--            'text-h6': $vuetify.breakpoint.mdAndDown,-->
        <!--            'text-h5': $vuetify.breakpoint.lgAndUp-->
        <!--            }">-->
        <!--                <strong>IVYED</strong>에 오신 것을 환영합니다!-->
        <!--            </span>-->
        <!--        </v-card>-->
<!--        {{studentContent}}-->
        <StudentMatchingInfoForm v-if="studentContent.studentRegisterStatus == 'NOT_STARTED' && studentContent.matchingStatus != 'MATCHING_INFO_FILLED'"></StudentMatchingInfoForm>
        <Enrollment v-if="studentContent.studentRegisterStatus == 'NOT_STARTED' && studentContent.matchingStatus == 'MATCHING_INFO_FILLED'"></Enrollment>
        <!-- 학생으로 가입했지만 아직 우리에게 등록하지 않았을시   -->
        <div>

            <!--  SHOWING VARIOUS MESSAGES SECTION -->
            <v-card
                    class="mx-auto mt-5 primary--text"
                    max-width="800"
                    elevation="0"

            >
                <!-- 결제 전 -->

                <!-- 결제 후 -->
                <div class="" v-show="studentContent.studentRegisterStatus == 'REGISTERED'">

                    <!-- 과외 신청서 작성 전 -->
                    <v-card color="" height="250" v-if="studentContent.matchingStatus=='NOT_STARTED'"
                            class="mt-5 primary--text status-card" flat elevation="0">
                        <span

                                class="mt-15 black--text"
                                :class="{
                                        'text-subtitle-2': $vuetify.breakpoint.mdAndDown,
                                        '': $vuetify.breakpoint.lgAndUp}">
                            <span class="ml-1">아직 과외 신청서가 등록되지 않았습니다!</span>
                            <br/>
                            <span class="ml-1 hidden-sm-and-down"> 과외 신청서를 등록해주셔야 선생님 매칭이 진행됩니다!</span>
                            <span class="ml-1 hidden-md-and-up"> 신청서를 등록해주셔야</span>
                            <span class="ml-1 hidden-md-and-up">선생님 매칭이 진행됩니다! </span>

                        </span>
                        <div class="container mt-15">
                            <ul class="progressbar">
                                <li class="active">수강 신청</li>
                                <li>과외 신청서</li>
                                <li>매칭</li>
                                <li>수업</li>
                            </ul>
                        </div>
                    </v-card>
                </div>

            </v-card>
                <!--                    <div>아이비에드에 가입하신걸 환영합니다. </div>-->
                <!--                    <div class="ml-5  hidden-sm-and-down black&#45;&#45;text text-h6"> <strong>수강 신청</strong>하시면 <strong>{{currentUser.firstName}}</strong>님에게 딱 맞는 선생님을 찾아드립니다.</div>-->
                <!--                    <div class=" hidden-md-and-up black&#45;&#45;text ml-5"> <strong>수강 신청</strong>하시면 <br/></div>-->
                <!--                    <div class="hidden-md-and-up black&#45;&#45;text ml-5"><strong>{{currentUser.firstName}}</strong>님에게 딱 맞는 선생님을 찾아드립니다.</div>-->
                <!--                    &lt;!&ndash;                    <v-divider class="mb-3 mt-3"></v-divider>&ndash;&gt;-->
                <!--                    <div class="mt-15 mb-15"-->
                <!--                         :class="{'text-subtitle-2': $vuetify.breakpoint.mdAndDown, '': $vuetify.breakpoint.lgAndUp}">-->
                <!--                        <div class="container py-10">-->
                <!--                            <ul class="progressbar">-->
                <!--                                <li class="active">수강 신청</li>-->
                <!--                                <li>과외 신청서</li>-->
                <!--                                <li>매칭</li>-->
                <!--                                <li>수업</li>-->
                <!--                            </ul>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                    <div align="center" v-if="!isEnrollmentDataEmpty()" class="hidden-sm-and-down">-->
                <!--                        <v-btn color="primary"-->
                <!--                               @click="proceedToPayment(currentUser.id)"> 지금 바로 등록하러 가기!-->
                <!--                        </v-btn>-->
                <!--                    </div>-->

                <!--                    &lt;!&ndash;  mobile / browser      &ndash;&gt;-->
                <!--                    <div class=" pb-15" v-if="isEnrollmentDataEmpty()" align="center">-->
                <!--                        <v-btn-->
                <!--                                class="ma-3"-->
                <!--                                color="secondary"-->
                <!--                                to="/enroll"-->
                <!--                                width="40%"-->

                <!--                        > 수강 신청-->
                <!--                        </v-btn>-->
                <!--                    </div>-->


            <v-card class="mx-auto  primary--text"
                    max-width="800"
                    elevation="0">

                <!-- 결제  -->
                <div class="mb-4" v-show="studentContent.studentRegisterStatus == 'REGISTERED'">

                    <!-- MATCHING NOT REQUESTED / before filling out matching form-->
                    <div v-if="studentContent.matchingStatus=='NOT_STARTED'">
                        <div class="card mb-5">
                            <div class="card__header">
                            </div>
                            <div class="card__body">
                                <span class="tag tag-blue">현재 이용중인 수강권</span>
                                <h2>{{this.studentContent.subjectsOfInterest[0]}}
                                </h2>
                                <span class="text-h6">{{this.studentContent.subSubjects}}</span>
                                <div class="text-h6">{{"주 " +
                                    (this.studentContent.expectedNumberOfLessonsPerWeek+"").charAt(0) + "회, " +
                                    (this.studentContent.expectedNumberOfLessonsPerWeek+"").substring(1,3)+"분 수업"}} •
                                    {{studentContent.expectedLessonDuration}}개월
                                </div>
                                <v-card-text class="pa-2 mt-5">
                                    <v-icon class="mx-1" color="secondary">mdi-school</v-icon>
                                    <span class="text-subtitle-1 black--text"> 총 {{this.studentContent.expectedLessonDuration * parseInt((this.studentContent.expectedNumberOfLessonsPerWeek+"").charAt(0)) * 4}}회의 1:1 과외 수업</span>
                                </v-card-text>
                                <v-card-text class="pa-2">
                                    <v-icon class="mx-1" color="secondary">mdi-credit-card</v-icon>
                                    <span class="text-subtitle-1 black--text"> {{this.studentContent.expectedLessonDuration}}개월 분
                                        <span class="text-caption">
                                            (총 {{this.studentContent.expectedLessonDuration * parseInt((this.studentContent.expectedNumberOfLessonsPerWeek+"").charAt(0)) * 4}}회)
                                        </span>
                                        <br/>
                                        <span class="ml-9 font-weight-bold">수업 종료 후 1개월씩 자동 결제</span> <br/>
                                        <span class="ml-9">잔여 수업: {{this.sumOfRemainingClasses}}</span>
                                    </span>
                                </v-card-text>
                            </div>
                            <div class="card__footer text-center">
                                <v-btn v-if="studentContent.matchingStatus == 'NOT_STARTED'"
                                       to="/student/matching/info"
                                       class=" mx-auto my-4" x-large width="250" color="secondary">
                                    <v-icon>mdi-pencil</v-icon>
                                    과외 신청서 등록하기
                                </v-btn>
                            </div>
                        </div>

                        <div class="payment-card" v-if="studentCardInfo.code == 1">
                            <div class="card__header">
                            </div>
                            <div class="card__body">
                                <span class="tag tag-red">현재 등록된 카드가 없습니다.</span>
                                <!--                                <h2>XX카드 ({{String(this.studentContent.payments[0].cardNumber).slice(-4)}})</h2>-->
                                <!--                                <h2>XX카드 ({{String(studentCardInfo.response.cardNumber).slice(-4)}})</h2>-->
                            </div>
                            <div class="card__footer text-center">

                                <v-btn
                                        class="mx-auto my-4 primary--text" x-large width="250"
                                        elevation="0"
                                        outlined
                                        disabled
                                        :to="'/payment/card/info'"
                                >
                                    카드 변경하기
                                </v-btn>

                            </div>
                        </div>

                        <div class="payment-card" v-if="studentCardInfo.response">
                            <div class="card__header">
                            </div>
                            <div class="card__body">
                                <span class="tag tag-red">현재 등록된 카드</span>
                                <!--                                <h2>XX카드 ({{String(this.studentContent.payments[0].cardNumber).slice(-4)}})</h2>-->
                                <!--                                <h2>XX카드 ({{String(studentCardInfo.response.cardNumber).slice(-4)}})</h2>-->
                                <h2>{{studentCardInfo.response.cardName}}
                                    ({{String(studentCardInfo.response.cardNumber).slice(-4)}})</h2>
                            </div>
                            <div class="card__footer text-center">

                                <v-btn
                                        class="mx-auto my-4 primary--text" x-large width="250"
                                        elevation="0"
                                        outlined
                                        :to="'/payment/card/info'"
                                >
                                    카드 변경하기
                                </v-btn>

                            </div>
                        </div>

                        <div class="my-15">
                            <span> 과외를 변경하거나 중단하고 싶은 경우엔, <strong>카카오톡 IVYED 매니저</strong>에게 연락주세요.</span>
                        </div>
                        <v-divider></v-divider>
                    </div>
                    <v-container v-if="studentContent.matchingStatus=='MATCHING_INFO_FILLED'">
                        <span
                                class="black--text"
                                :class="{
                                'text-h7': $vuetify.breakpoint.lgAndUp,
                                'text-h7': $vuetify.breakpoint.mdAndDown,

                                }">
                            <p>과외 신청서 작성 완료!</p>
                            <p>
                                <br/>
                                <strong>[바로 선생님 매칭하기]</strong>를 누르시면 <br/>
                                {{this.currentUser.firstName}}님과 잘 맞는 최적의 IVYED 선생님을 찾아드립니다!
                            </p>
                        </span>

                        <div class="container mt-7 pb-15">
                            <ul class="progressbar">
                                <li>수강 신청</li>
                                <li class="active">과외 신청서</li>
                                <li>매칭</li>
                                <li>수업</li>
                            </ul>
                        </div>
                        <div
                                class="mx-auto mt-15  mb-5 primary--text text-center"
                                max-width="750"
                                outlined
                        >
                            <v-btn color="secondary" class="ma-2" large
                                   v-if="studentContent.matchingStatus == 'MATCHING_INFO_FILLED'"
                                   @click="updateMatchingStatus(currentUser.id, studentContent)">
                                바로 선생님 매칭하기 &nbsp;<v-icon>mdi-human-male-board</v-icon>
                            </v-btn>
                            <v-btn color="secondary" outlined class="ma-2" large
                                   v-if="studentContent.matchingStatus == 'MATCHING_INFO_FILLED'"
                                   to="/student/matching/info">
                                과외 신청서 수정하기 &nbsp;<v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                        </div>
                        <v-divider></v-divider>
                    </v-container>


                    <!-- MATCHING REQUESTED  -->
                    <v-container v-if="studentContent.matchingStatus=='REQUESTED'">
                        <h2 class="mb-5 mt-5">
                        </h2>
                        <div class="black--text"
                             :class="{

                                }">
                            {{currentUser.firstName}}님과 잘 맞는 IVYED 선생님을 찾는 중입니다.
                            <br/>
                            배정되는 즉시 연락드리겠습니다. 😊

                        </div>
                        <br/>
                        <div class="black--text" v-if="!userContent.contractSigned">
                            과외 규정 안내 계약서도 이메일로 전송되었습니다 (모두싸인).
                            <br/>
                            부모님 전자서명이 필요하여 전달 부탁드립니다 (메일 포워드도 가능)

                            <br/>
                            <div class="font-italic">열람 시 이메일 주소 >> 전화번호 뒤에 4자리 입력하시면 됩니다!</div>
                        </div>
                        <div
                                class="mx-auto mt-15 mb-5 primary--text"
                                max-width="750"
                                outlined
                        >
                            <div class="container my-15 pb-15">
                                <ul class="progressbar">
                                    <li>수강 신청</li>
                                    <li>과외 신청서</li>
                                    <li class="active">매칭</li>
                                    <li>수업</li>
                                </ul>
                            </div>
                        </div>
                    </v-container>

                    <!-- MATCHING FOUND  -->
                    <!--                            {{studentContent.matchingStatus}}-->
                    <v-card v-if="studentContent.matchingStatus=='FOUND'" class="black--text" elevation="0">
                        <div class="ma-3">
                            <p class="text-subtitle-1">학생분과 잘 맞을 것 같은 선생님을 찾았습니다!</p>
                            <p class="mb-5 mt-5 text-subtitle-1">
                                <strong>[수업 진행]</strong>을 눌러 선생님과 상세 수업 일정 조율을 시작하세요!
                            </p>
                            <v-card height="100px" elevation="0">
                                <div class="container my-15">
                                    <ul class="progressbar">
                                        <li>수강 신청</li>
                                        <li>과외 신청서</li>
                                        <li class="active">매칭</li>
                                        <li>수업</li>
                                    </ul>
                                </div>
                            </v-card>
                        </div>
                        <!--                        {{lessonsExceptCancelled.length}}-->
                        <div class=" pa-2  mt-15 mb-10">
                            <v-card elevation="5" class="black--text rounded-xl" v-for="item in lessonsExceptCancelled"
                                    :key="item.id">


                                <!--                            <div class="text-subtitle-2 ma-2"> 요청 시간: {{new Date(item.updated_at)}}</div>-->
                                <!--                            <v-divider></v-divider>-->
                                <div v-if="item.lesson_status != 9" class="pa-2">
                                    <v-card-title class="font-weight-bold text-decoration-underline mt-2">
                                        선생님 정보
                                    </v-card-title>
                                    <div class="mx-5 mt-2">

                                        <span class="text-subtitle-1 font-weight-bold ">대학교<br/></span>
                                        <span class="text-decoration-underline"> {{item.teacher_undergrad}} </span>
                                        {{item.teacher_undergrad_major}} 전공으로


                                        {{item.highest_level_of_education}}
                                        <span v-if="item.highest_level_of_education.includes('졸업')">
<!--                                    하셨어요.-->
                                </span>
                                        <span v-if="item.highest_level_of_education.includes('중')">
<!--                                    이세요.-->
                                </span>
                                        <span v-if="item.teacher_grad_school != null">

                                </span>


                                    </div>
                                    <br/>
                                    <div class="mx-5">
                                        <span class="text-subtitle-1 font-weight-bold ">고등학교<br/></span>
                                        {{item.teacher_high_school}} ({{item.teacher_high_school_type}})
                                        졸업
                                    </div>
                                    <br/>
                                    <div class="mx-5 mb-2" v-if="item.teacher_personal_intro != null">
                                        <span class="text-subtitle-1 font-weight-bold ">선생님 소개<br/></span>
                                        "{{item.teacher_personal_intro}}"
                                    </div>
                                    <v-divider></v-divider>
                                    <v-container>
                                        <v-card elevation="0" class="black--text">
                                            <v-card-title class="font-weight-bold text-decoration-underline mt-2">
                                                특별 요청 사항
                                            </v-card-title>
                                            <v-form v-model="valid">
                                                <v-container fluid>
                                                    <v-textarea
                                                            name="input-7-1"
                                                            filled
                                                            class="black--text text-subtitle-2"
                                                            placeholder="예) AP Computer Science 문제 풀이와 학교 프로젝트 번갈아 가면서 진행 부탁드립니다."
                                                            auto-grow
                                                            v-model="appointment.message"
                                                    ></v-textarea>
                                                </v-container>
                                                <div
                                                        class="mx-auto mt-5 ml-5 mb-5 text-center primary--text"
                                                        max-width="750"
                                                        outlined
                                                >
                                                    <!--                                        {{item}}-->
                                                    <!--                                                {{item}}-->
                                                    <v-btn color="secondary" class="mr-5"
                                                           :disabled="!valid"
                                                           @click="makeLessonAppointment(item.lesson_id, appointment, item.student_id, item.teacher_id)"
                                                    >
                                                        수업 진행
                                                    </v-btn>
                                                </div>
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </div>
                            </v-card>
                        </div>

                    </v-card>

                    <!-- 2022/05/13 선생님 기다리는동안 보낼 메시지 필요                    -->
                    <!--                    <v-container v-if="studentContent.matchingStatus=='WAITING_FOR_TEACHER_CONFIRMATION'" class="both-accepted-container">-->
                    <!--                    {{Object.keys(studentContent.lessons).length}}-->
                    <div v-if="studentContent.lessons && studentContent.lessons.length > 0">
                        <!--{{studentContent.lessons}}-->
                        <v-container
                                v-if="studentContent.lessons[newestIndex].status == 'BOTH_ACCEPTED'"
                                class="both-accepted-container black--text">
                            <p>선생님과 비대면으로 수업할 과외방 생성 중입니다.<br/>
                                1영업일 내로 선생님과 함께 연락드리겠습니다!🙂</p>
                            <!--                        <h4>선생님께서 최종 수락하시면 과외를 시작합니다! </h4>-->
                            <v-divider class="mb-3 mt-3"></v-divider>
                            <v-card height="100px" elevation="0" class="mb-7">
                                <div class="container my-15">
                                    <ul class="progressbar">
                                        <li>수강 신청</li>
                                        <li>과외 신청서</li>
                                        <li class="active">매칭</li>
                                        <li>수업</li>
                                    </ul>
                                </div>
                            </v-card>

                        </v-container>
                        <!--                        {{}}-->
                        <!--                        {{studentContent.lessons[studentContent.lessons.length - 1]}} <br/>-->
                        <!--                        {{newestIndex}} <br/>-->
                        <!--  Registered 된 학생이나 credit 소진 되었을시 (수업 연장) -->
                        <div v-if="studentContent.studentRegisterStatus == 'REGISTERED' && studentContent.matchingStatus!='NOT_STARTED' && studentContent.sumOfRemainingClasses == 0">
                            <v-card class="rounded-lg" elevation="4">
                                <div class="">
                                    <v-card-title>
                                        <v-icon color="primary">mdi-information-outline</v-icon> &nbsp; 현재 잔여 수강권이 존재 하지
                                        않습니다.
                                    </v-card-title>
                                    <v-card-subtitle>수업 연장을 원하시면 아래 "결제하기"를 클릭하신 후 수강권 구매를 진행 해주세요.</v-card-subtitle>
                                    <div class="text-center">
                                        <v-btn
                                                to="/payment"
                                                class=" mx-auto my-4" x-large width="250" color="secondary">
                                            <!--                                            <v-icon>mdi-account-credit-card-outline</v-icon>-->
                                            결제하기
                                        </v-btn>
                                    </div>
                                </div>
                            </v-card>
                        </div>
                        <div class="my-4"></div>
                        <div v-if="getDefaultLanguage.includes('ko')">
                            <v-container class="black--text"
                                         v-if="studentContent.lessons[newestIndex].status == 'SCHEDULED' && studentContent.sumOfRemainingClasses > 0">

                                <v-card-subtitle class="">
                                    <v-icon class="pb-1">mdi-information-outline</v-icon>
                                    <span style=""> 수업일지 및 영상은 수업 후 1~2 Business days내에 업데이트 됩니다.</span>
                                </v-card-subtitle>
                                <v-card class="py-5 px-2 rounded-xl" elevation="3" max-width="">
                                    <v-chip class="ma-3"
                                            style="background: #cb2d3e; background: linear-gradient(to bottom, #ef473a, #cb2d3e); color: #fafafa;">
                                        예정된 과외
                                    </v-chip>
                                    <v-card-title class="font-weight-bold text-decoration-underline">
                                        수업 정보
                                    </v-card-title>
                                    <div class="mx-5">
                                        <div class="font-weight-bold">
                                            과목
                                        </div>
                                        {{studentContent.subjectsOfInterest[0]}} - {{studentContent.subSubjects}}
                                        <br/>
                                        <div class="font-weight-bold mt-3">
                                            수업 횟수
                                        </div>
                                        {{"주 " +
                                        (this.studentContent.expectedNumberOfLessonsPerWeek+"").charAt(0) + "회, " +
                                        (this.studentContent.expectedNumberOfLessonsPerWeek+"").substring(1,3)+"분 수업"}}
                                        <br/>

                                    </div>
                                    <v-divider class="ma-4"></v-divider>

                                    <v-card-title class="font-weight-bold text-decoration-underline mt-4">
                                        선생님 정보
                                    </v-card-title>
                                    <div class="mx-5">
                                        <div class="font-weight-bold">
                                            이름
                                        </div>
                                        {{getTeacherInfoById(scheduledLessons[0].tid).teacher_lastname}}
                                        {{getTeacherInfoById(scheduledLessons[0].tid).teacher_firstname}}
                                        <br/>
                                        <div class="font-weight-bold mt-3">
                                            카톡 ID
                                        </div>
                                        {{getTeacherInfoById(scheduledLessons[0].tid).teacher_kakao_id}}
                                        <br/>
                                        <div class="font-weight-bold mt-3">
                                            이메일
                                        </div>
                                        {{getTeacherInfoById(scheduledLessons[0].tid).teacher_email}}
                                        <br/>
                                        <div class="font-weight-bold mt-3">
                                            전화번호
                                        </div>
                                        <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_country_code == '직접 입력'">{{getTeacherInfoById(scheduledLessons[0].tid).teacher_phone_number}}</span>
                                        <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_country_code != '직접 입력' && getTeacherInfoById(scheduledLessons[0].tid).teacher_country_code != null"> (+{{getTeacherInfoById(scheduledLessons[0].tid).teacher_country_code.replace(/\D/g,'')}}) {{getTeacherInfoById(scheduledLessons[0].tid).teacher_phone_number}}</span>

                                        <br/>
                                        <br/>

                                    </div>
                                    <v-divider class="ma-4"></v-divider>

                                    <v-card-title class="font-weight-bold text-decoration-underline mt-4">
                                        학교 정보
                                    </v-card-title>
                                    <div class="mx-5">
                                        <div></div>
                                        <div>
                                            <div class="">
                                                <div class="text-subtitle-1 "><strong>대학교<br/> </strong>
                                                    {{getTeacherInfoById(scheduledLessons[0].tid).teacher_undergrad_major}}
                                                    전공으로
                                                    <span class="text-decoration-underline">{{getTeacherInfoById(scheduledLessons[0].tid).teacher_undergrad}}</span>
                                                    <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_school != null"
                                                          class=""> 졸업 </span>
                                                    <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_school == null">{{getTeacherInfoById(scheduledLessons[0].tid).highest_level_of_education}}</span>
                                                </div>
                                            </div>
                                            <div class="mt-3"
                                                 v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_school != null">
                                        <span class="text-subtitle-1 "><strong>대학원<br/> </strong> {{getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_major}} 전공으로
                                            <span class="text-decoration-underline">{{getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_school}} </span></span>
                                                {{getTeacherInfoById(scheduledLessons[0].tid).highest_level_of_education}}
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <span class="text-subtitle-1 font-weight-bold">고등학교 <br/></span>
                                            <span class="text-decoration-underline">{{getTeacherInfoById(scheduledLessons[0].tid).teacher_high_school}}</span>
                                            ({{getTeacherInfoById(scheduledLessons[0].tid).teacher_high_school_type}})
                                            졸업
                                        </div>
                                        <div v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_personal_intro != null"
                                             class="mt-3">
                                            <span class="text-subtitle-1 font-weight-bold">선생님 소개<br/></span>
                                            {{getTeacherInfoById(scheduledLessons[0].tid).teacher_personal_intro}}
                                        </div>
                                        <div class=" text-center mb-4 mt-5">
                                            <!--                                        <row>-->
                                            <v-btn class="my-3 " elevation="1" :href="scheduledLessons[0].pageCallLink"
                                                   target="_blank" color="secondary">과외방 입장
                                            </v-btn>
                                            <span class="mx-2"></span>
                                            <v-btn class="my-3 " elevation="1" outlined
                                                   :href="scheduledLessons[0].reportLink"
                                                   target="_blank" color="primary">수업일지 보기
                                            </v-btn>
                                            <span class="mx-2"></span>
                                            <v-btn class="my-3 " elevation="1" outlined
                                                   :href="scheduledLessons[0].schedule"
                                                   target="_blank" color="secondary">수업영상 보기
                                            </v-btn>
                                            <!--                                        </row>-->
                                        </div>

                                    </div>

                                </v-card>

                            </v-container>
                        </div>
                        <div v-if="getDefaultLanguage.includes('en')">
                            <v-container class="black--text"
                                         v-if="studentContent.lessons[newestIndex].status == 'SCHEDULED' && studentContent.sumOfRemainingClasses > 0">
                                <v-card-subtitle class="">
                                    <v-icon class="pb-1" color="primary">mdi-information-outline</v-icon>
                                    <span style=""> Feedback report and video recording will be uploaded within 1~2 business days after each lesson.</span>
                                    <br/>
                                    <v-icon class="pb-1" color="secondary">mdi-lightbulb-outline</v-icon>
                                    <span style=""> For each lesson, be sure to communicate with your teacher about HW assignments for next class!</span>
                                    <!--                                    <br/>-->
                                    <!--                                    <span style=""> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>-->

                                </v-card-subtitle>
                                <v-card class="py-5 px-2 rounded-xl" elevation="3" max-width="">
                                    <v-chip class="ma-3"
                                            style="background: #cb2d3e; background: linear-gradient(to bottom, #ef473a, #cb2d3e); color: #fafafa;">
                                        SCHEDULED
                                    </v-chip>
                                    <v-card-title class="font-weight-bold text-decoration-underline">
                                        About Lesson
                                    </v-card-title>
                                    <div class="mx-5">
                                <span class="font-weight-bold">
                        Subject <br/>
                        </span> {{studentContent.subjectsOfInterest[0]}} - {{studentContent.subSubjects}}
                                        <br/>
                                        <div class="font-weight-bold mt-3">
                                            Number of lessons
                                        </div>
                                        {{

                                        (this.studentContent.expectedNumberOfLessonsPerWeek+"").charAt(0)}} lesson(s) per week •
                                        {{(this.studentContent.expectedNumberOfLessonsPerWeek+"").substring(1,3) + " minutes "}} per lesson
                                        <br/>
                                        <!--                                    <div class=" text-center mb-4 mt-2">-->
                                        <!--                                        <row>-->
                                        <!--                                            <v-btn class="my-3 mr-7" elevation="1" :href="''"-->
                                        <!--                                                   target="_blank" color="secondary">과외방 입장-->
                                        <!--                                            </v-btn>-->
                                        <!--                                            <v-btn class="my-3" elevation="1" outlined :href="''"-->
                                        <!--                                                   target="_blank" color="secondary">수업일지 보기-->
                                        <!--                                            </v-btn>-->
                                        <!--                                        </row>-->
                                        <!--                                    </div>-->

                                    </div>
                                    <!--                            <div class="ml-6">-->
                                    <!--                                <span class="text-subtitle-1 font-weight-bold text-decoration-underline">선생님 <br/></span>-->
                                    <!--                                <span><strong>{{studentContent.subjectsOfInterest[0]}} </strong>과외 with <strong>{{getTeacherInfoById(scheduledLessons[0].tid).teacher_lastname}} {{getTeacherInfoById(scheduledLessons[0].tid).teacher_firstname}}</strong> 선생님</span>-->
                                    <!--                                <br/>-->
                                    <!--                                <br/>-->
                                    <!--                                <span class="text-subtitle-1 font-weight-bold text-decoration-underline">과외 일정 <br/></span>-->
                                    <!--                                <span><strong>{{scheduledLessons[0].schedule}} </strong></span>-->
                                    <!--                                <br/>-->
                                    <!--                                <br/>-->
                                    <!--                                <span class="text-subtitle-1 font-weight-bold text-decoration-underline">수업용 화이트보드 <br/></span>-->
                                    <!--                                <p><v-btn class="my-3" outlined :href="scheduledLessons[0].pageCallLink" target="_blank" color="primary">입장하기</v-btn></p>-->

                                    <!--&lt;!&ndash;                                <span><strong>{{scheduledLessons[0].pageCallLink}} </strong></span>&ndash;&gt;-->


                                    <!--                            </div>-->
                                    <v-divider class="ma-4"></v-divider>

                                    <v-card-title class="font-weight-bold text-decoration-underline">
                                        Contact Information
                                    </v-card-title>
                                    <v-card-subtitle>
                                        * For any questions that need to be clarified, please feel free to contact your
                                        teacher!
                                    </v-card-subtitle>
                                    <div class="mx-5">
                                        <div class="font-weight-bold">
                                            Name<br/>
                                        </div>
                                        {{getTeacherInfoById(scheduledLessons[0].tid).teacher_lastname}}
                                        {{getTeacherInfoById(scheduledLessons[0].tid).teacher_firstname}}
                                        <br/>
                                        <div class="font-weight-bold mt-3">
                                            Current Location<br/>
                                        </div>
                                        {{getTeacherInfoById(scheduledLessons[0].tid).teacher_location}}
                                        <br/>
                                        <div class="font-weight-bold mt-3">
                                            Kakao ID
                                        </div>
                                        {{getTeacherInfoById(scheduledLessons[0].tid).teacher_kakao_id}}
                                        <br/>
                                        <div class="font-weight-bold mt-3">
                                            Email
                                        </div>
                                        {{getTeacherInfoById(scheduledLessons[0].tid).teacher_email}}
                                        <br/>
                                        <div class="font-weight-bold mt-3">
                                            Phone number
                                        </div>
                                        <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_country_code == '직접 입력'">{{getTeacherInfoById(scheduledLessons[0].tid).teacher_phone_number}}</span>
                                        <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_country_code != '직접 입력' && getTeacherInfoById(scheduledLessons[0].tid).teacher_country_code != null"> (+{{getTeacherInfoById(scheduledLessons[0].tid).teacher_country_code.replace(/\D/g,'')}}) {{getTeacherInfoById(scheduledLessons[0].tid).teacher_phone_number}}</span>


                                        <br/>
                                    </div>
                                    <v-divider class="ma-4"></v-divider>

                                    <v-card-title class="font-weight-bold text-decoration-underline">School
                                    </v-card-title>

                                    <div class="mx-5">

                                        <div>
                                            <div class="">

                                                <div class="mt-1">
                                                    <div class="font-weight-bold">Undergraduate</div>
                                                    <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_school != null">Graduated undergraduate at </span>
                                                    <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_school == null">Attending undergraduate at </span>
                                                    <span class="text-decoration-underline">{{getTeacherInfoById(scheduledLessons[0].tid).teacher_undergrad}}</span>
                                                    as a
                                                    {{getTeacherInfoById(scheduledLessons[0].tid).teacher_undergrad_major}}
                                                    major
                                                </div>
                                                <div class="mt-4"
                                                     v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_school != null">
                                                    <div class="font-weight-bold">Graduate School</div>
                                                    <span v-if="getTeacherInfoById(scheduledLessons[0].tid).highest_level_of_education.includes('재학')">Attending grad school at </span>
                                                    <span v-if="getTeacherInfoById(scheduledLessons[0].tid).highest_level_of_education.includes('석사')">Finished Master's at </span>
                                                    <span v-if="getTeacherInfoById(scheduledLessons[0].tid).highest_level_of_education.includes('박사')">Finished PhD's at </span>
                                                    <span class="text-decoration-underline">{{getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_school}}</span>
                                                    as a
                                                    {{getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_major}}
                                                    major
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="text-subtitle-1 font-weight-bold mt-4">High School <br/></div>
                                            <div>Graduated
                                                {{getTeacherInfoById(scheduledLessons[0].tid).teacher_high_school}}
                                                <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_high_school_type.includes('통학')">(Public / Day School)</span>
                                                <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_high_school_type.includes('기숙')">(Boarding School)</span>
                                                <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_high_school_type.includes('국제')">(Foreign/Int'l School)</span>
                                                <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_high_school_type.includes('외국어')">(Foreign Language School)</span>
                                                <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_high_school_type.includes('과학')">(Science High School)</span>
                                                <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_high_school_type.includes('홈스쿨링')">(Home school)</span>
                                            </div>
                                        </div>
                                        <!--                                        <div class="mt-4" v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_personal_intro != null">-->
                                        <!--                                            <div class="text-subtitle-1 font-weight-bold">About Teacher <br/></div>-->
                                        <!--                                            {{getTeacherInfoById(scheduledLessons[0].tid).teacher_personal_intro}}-->
                                        <!--                                        </div>-->

                                        <div class=" text-center mb-4 mt-8">
                                            <!--                                        <row>-->
                                            <v-btn class="my-3 " elevation="1" :href="scheduledLessons[0].pageCallLink"
                                                   target="_blank" color="secondary">Enter Classroom
                                            </v-btn>
                                            <span class="px-2"></span>
                                            <v-btn class="my-3 " elevation="1" outlined
                                                   :href="scheduledLessons[0].reportLink"
                                                   target="_blank" color="primary">Feedback Report
                                            </v-btn>
                                            <span class="px-2"></span>
                                            <v-btn class="my-3 " elevation="1" outlined
                                                   :href="scheduledLessons[0].schedule"
                                                   target="_blank" color="secondary">Class Recordings
                                            </v-btn>
                                            <span class="px-2"></span>
                                            <!--                                        </row>-->
                                        </div>

                                    </div>


                                </v-card>

                            </v-container>
                        </div>
                    </div>
                    <div v-if="studentContent.studentRegisterStatus == 'REGISTERED' && studentContent.matchingStatus!='NOT_STARTED'">
                        <div class="card mb-5">
                            <div class="card__header">
                            </div>
                            <div class="card__body">
                                <div class="tag tag-blue">현재 이용중인 수강권</div>
                                <h2>{{this.studentContent.subjectsOfInterest[0]}}
                                </h2>
                                <div class="text-h6">{{this.studentContent.subSubjects}}</div>
                                <div class="text-h6">{{"주 " +
                                    (this.studentContent.expectedNumberOfLessonsPerWeek+"").charAt(0) + "회, " +
                                    (this.studentContent.expectedNumberOfLessonsPerWeek+"").substring(1,3)+"분 수업"}} •
                                    {{studentContent.expectedLessonDuration}}개월
                                </div>
                                <v-card-text class="pa-2 mt-5">
                                    <v-icon class="mx-1" color="secondary">mdi-school</v-icon>
                                    <span class="text-subtitle-1 black--text"> 총 {{this.studentContent.expectedLessonDuration * parseInt((this.studentContent.expectedNumberOfLessonsPerWeek+"").charAt(0)) * 4}}회의 1:1 과외 수업</span>
                                </v-card-text>
                                <v-card-text class="pa-2">
                                    <v-icon class="mx-1" color="secondary">mdi-credit-card</v-icon>
                                    <span class="text-subtitle-1 black--text"> {{this.studentContent.expectedLessonDuration}}개월 분
                                        <span class="text-caption">
                                            (총 {{this.studentContent.expectedLessonDuration * parseInt((this.studentContent.expectedNumberOfLessonsPerWeek+"").charAt(0)) * 4}}회)
                                        </span>
                                        <br/>
                                        <span class="ml-9 font-weight-bold">수업 종료 후 1개월씩 자동 결제</span><br/>
                                        <!--                                        {{studentContent.payments[0]}}-->
                                        <span class="ml-9">잔여 수업: {{this.sumOfRemainingClasses}}</span>
                                    </span>
                                </v-card-text>
                            </div>
                            <div class="card__footer text-center">
                                <v-btn v-if="studentContent.matchingStatus == 'NOT_STARTED'"
                                       to="/student/matching/info"
                                       class=" mx-auto my-4" x-large width="250" color="secondary">
                                    <v-icon>mdi-pencil</v-icon>
                                    과외 신청서 등록하기
                                </v-btn>
                            </div>
                        </div>
                        <div class="payment-card" v-if="studentCardInfo.code == 1">
                            <div class="card__header">
                            </div>
                            <div class="card__body">
                                <span class="tag tag-red">현재 등록된 카드가 없습니다.</span>
                                <!--                                <h2>XX카드 ({{String(this.studentContent.payments[0].cardNumber).slice(-4)}})</h2>-->
                                <!--                                <h2>XX카드 ({{String(studentCardInfo.response.cardNumber).slice(-4)}})</h2>-->
                            </div>
                            <div class="card__footer text-center">

                                <v-btn
                                        class="mx-auto my-4 primary--text" x-large width="250"
                                        elevation="0"
                                        outlined
                                        disabled
                                        :to="'/payment/card/info'"
                                >
                                    카드 변경하기
                                </v-btn>

                            </div>
                        </div>

                        <div class="payment-card" v-if="studentCardInfo.response">
                            <div class="card__header">
                            </div>
                            <div class="card__body">
                                <span class="tag tag-red">현재 등록된 카드</span>
                                <h2>{{studentCardInfo.response.cardName}}
                                    ({{String(studentCardInfo.response.cardNumber).slice(-4)}})</h2>
                                <!--                                <h2>XX카드 ({{String(this.studentContent.payments[0].cardNumber).slice(-4)}})</h2>-->
                            </div>
                            <div class="card__footer text-center">

                                <v-btn
                                        class="mx-auto my-4 primary--text" x-large width="250"
                                        elevation="0"
                                        outlined
                                        :to="'/payment/card/info'"

                                >
                                    카드 변경하기
                                </v-btn>

                            </div>
                        </div>

                        <div class="my-15">
                            <span> 과외를 변경하거나 중단하고 싶은 경우엔, <strong>카카오톡 IVYED 매니저</strong>에게 연락주세요.</span>
                        </div>
                        <!--                        <v-divider></v-divider>-->
                    </div>

                </div>
            </v-card>
        </div>


    </div>
</template>

<script>

    import StudentService from "../../services/student.service"
    import LessonService from "../../services/lesson.service"
    import EmailService from '../../services/email.service'
    import UserService from '../../services/user.service'
    // import Enrollment from "./Enrollment/Enrollment";
    import StudentMatchingInfoForm from "./Matching/StudentMatchingInfoForm";
    import Enrollment from "./Enrollment/Enrollment";
    // import TeacherService from '../../services/teacher.service'
    // import CardViewMore from "../helpers/Card-View-More";
    // import CardViewMore from "../helpers/Card-View-More";
    // import StudentInfo from "./StudentInfo";
    // import StudentMatchingNotStarted from "./StudentMatchingNotStarted";
    // import StudentMatchingRequested from "./StudentMatchingFound";
    // import StudentMatchingFound from "./StudentMatchingFound";

    export default {
        name: "StudentProfile",
        components: {
            Enrollment, StudentMatchingInfoForm
        },
        // components: {CardViewMore},
        // components: {CardViewMore},
        computed: {
            getDefaultLanguage() {
                return window.navigator.userLanguage || window.navigator.language;
            },
            currentUser() {
                return this.$store.state.auth.user;
            },
            enrollmentData() {
                return this.$store.state.enrollmentData;
            },
            lessonsExceptCancelled: function () {
                return this.lessons.filter(function (lesson) {
                    return lesson.lesson_status != 9;
                })
            }
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }

            // add student info fetcher
            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                    for (let i = 0; i < this.studentContent.lessons.length; i++) {
                        if (this.studentContent.lessons[i].id > this.newestIndex) {
                            this.newestIndex = this.studentContent.lessons[i].id;
                        }
                        if (this.studentContent.lessons[i].status == 'SCHEDULED') {
                            this.scheduledLessons.push(this.studentContent.lessons[i])
                        }
                    }
                    for (let i = 0; i < this.studentContent.lessons.length; i++) {
                        if (this.studentContent.lessons[i].id == this.newestIndex) {
                            this.newestIndex = i;
                        }
                    }
                    for (let i = 0; i < this.studentContent.payments.length; i++) {
                        this.sumOfRemainingClasses += this.studentContent.payments[i].remainingNumberOfClasses

                    }

                    console.log(this.studentContent)
                    // for (let i = 0; i < this.studentContent.lessons.length; i++) {
                    //     if (this.studentContent.lessons[i].status == 'SCHEDULED') {
                    //         this.scheduledLessons.push(this.studentContent.lessons[i])
                    //     }
                    // }
                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(this.studentContent)
                    if (this.studentContent == 'Access is denied' || this.studentContent.includes('denied') || this.studentContent.includes('401')) {
                        this.logOut();
                    }
                }
            );
            StudentService.getLessonInfoByStudentId(this.currentUser.id).then(
                (response) => {
                    // console.log(response.data)
                    // this.lessons.subjects_of_expertise = this.removeEmptyStringFromList(this.lessons.subjects_of_expertise)
                    this.lessons = response.data;
                },
                (error) => {
                    this.lessons =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            UserService.getUserInfo(this.currentUser.id).then(
                (response) => {
                    // console.log(response.data)
                    // this.lessons.subjects_of_expertise = this.removeEmptyStringFromList(this.lessons.subjects_of_expertise)
                    this.userContent = response.data;
                },
                (error) => {
                    this.userContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            StudentService.getCardInfoOfStudent(this.currentUser.id).then(
                (response) => {
                    // console.log(response.data)
                    // this.lessons.subjects_of_expertise = this.removeEmptyStringFromList(this.lessons.subjects_of_expertise)
                    this.studentCardInfo = response.data;
                },
                (error) => {
                    this.studentCardInfo =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        methods: {
            logOut() {
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
            },
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },
            proceedToPayment(userId) {

                let requestBody = {
                    subjectsOfInterest: [],
                    subSubjects: [],
                    expectedNumberOfLessonsPerWeek: "",
                    expectedLessonDuration: "",
                    tablet: "",
                };
                requestBody.subjectsOfInterest.push(this.enrollmentData.lessonCategory);
                requestBody.subSubjects.push(this.enrollmentData.lessonSubCateogry);
                requestBody.expectedNumberOfLessonsPerWeek = this.removeStringFromString(this.enrollmentData.expectedNumberOfLessonsPerWeek);
                requestBody.expectedLessonDuration = this.removeStringFromString(this.enrollmentData.expectedLessonDuration);
                requestBody.tablet = this.enrollmentData.tablet;
                console.log(requestBody);
                StudentService.updateStudent(userId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(this.studentContent)
                    }
                )
                this.$router.push("/enroll");
                return userId;
            },

            updateStudent(userId, requestBody) {
                StudentService.updateStudent(userId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                        // this.sendNotificationEmail({
                        //     "email": this.currentUser.email,
                        //     "emailBody": "[WIP] 선생님 매칭 신청이 완료 되었습니다. 곧 매칭 해줄게 기둘"
                        // })
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            sendNotificationEmail(requestBody) {
                EmailService.sendEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            sendNotificationEmailByUserId(requestBody, userId) {
                EmailService.sendEmailNotificationByUserId(requestBody, userId).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            updateMatchingStatus(userId, requestBody) {
                requestBody.matchingStatus = 'REQUESTED';
                console.log("Here")
                this.updateStudent(userId, requestBody);
                this.$router.push("/profile")
            },

            stringToList(input) {
                return input.split(";").filter(element => {
                    return element !== '';
                });
            },
            removeStringFromString(input) {
                return input.replace(/\D/g, '');
            },

            isEnrollmentDataEmpty() {
                // if (Object.keys(this.enrollmentData).length > 0) {
                if (this.enrollmentData.length > 0) {
                    return false;
                }
                return true;
            },

            getSchedule() {
                this.appointment.schedule = this.days + " " + this.times;
                return this.days + " " + this.times;
            },

            makeLessonAppointment(lessonId, requestBody, studentId, teacherId) {
                requestBody.studentId = studentId.toString();
                requestBody.teacherId = teacherId.toString();
                requestBody.schedule = this.days + " " + this.times;
                LessonService.makeLessonAppointment(lessonId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                        // this.sendNotificationEmailByUserId({
                        //     "emailBody": "[TEST] 학생에게 과외 확정 요청이 왔어요. 들어가서 얼렁 보쇼",
                        //     "userId": teacherId,
                        // }, teacherId);
                        // return response.body;


                        this.$router.go(0)
                        // this.$router.go(this.$router.currentRoute)

                    },
                    (error) => {
                        this.appointmentResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            getTeacherInfoById(userId) {
                for (let i = 0; i < this.lessons.length; i++) {
                    if (this.lessons[i].teacher_id == userId) {
                        return this.lessons[i];
                    }
                }
            }
        },
        data: () => ({
            newestIndex: -1,
            sumOfRemainingClasses: 0,
            scheduledLessons: [],
            valid: false,
            studentContent: "",
            userContent: "",
            studentCardInfo: "",
            requestBody: "",
            appointmentResponseBody: "",
            lessons: [],
            continueLesson: false,
            scheduleTime: [],
            days: "",
            times: [],
            appointment: {
                schedule: "",
                message: "",
                studentId: "",
                teacherId: "",
            },
            hasEnrollmentData: false,
            bannerItems: [
                {
                    color: '#1F7087',
                    // src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
                    title: '비대면 수업은 어떻게 진행 되는지 알아보세요.',
                    artist: 'IVYeD 의 비대면 시스템을 알아보세요!',
                },
                {
                    color: '#952175',
                    // src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
                    title: '왜 IVYeD여야 하나요?',
                    artist: '동안..',
                },
            ],
        }),
    }
</script>

<style scoped>
    .button-82-pushable {
        position: relative;
        border: none;
        background: transparent;
        padding: 50px;
        cursor: pointer;
        outline-offset: 4px;
        transition: filter 250ms;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }


    .button-82-edge {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background: linear-gradient(
                to left,
                hsl(340deg 100% 16%) 0%,
                hsl(340deg 100% 32%) 8%,
                hsl(340deg 100% 32%) 92%,
                hsl(340deg 100% 16%) 100%
        );
    }

    .button-82-front {
        display: block;
        position: relative;
        padding: 12px 27px;
        border-radius: 12px;
        font-size: 1.1rem;
        color: white;
        background: hsl(345deg 100% 47%);
        will-change: transform;
        transform: translateY(-4px);
        transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
    }

    @media (min-width: 768px) {
        .button-82-front {
            font-size: 1.25rem;
            padding: 12px 42px;
        }
    }

    .button-82-pushable:hover {
        filter: brightness(110%);
    }

    .button-82-pushable:hover .button-82-front {
        transform: translateY(-6px);
        transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    .button-82-pushable:active .button-82-front {
        transform: translateY(-2px);
        transition: transform 34ms;
    }

    .button-82-pushable:hover .button-82-shadow {
        transform: translateY(4px);
        transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    .button-82-pushable:active .button-82-shadow {
        transform: translateY(1px);
        transition: transform 34ms;
    }

    .button-82-pushable:focus:not(:focus-visible) {
        outline: none;
    }

    .button-17 {
        align-items: center;
        appearance: none;
        background-color: #fff;
        border-radius: 24px;
        border-style: none;
        box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;
        box-sizing: border-box;
        color: #3c4043;
        cursor: pointer;
        display: inline-flex;
        fill: currentcolor;
        font-family: "Google Sans", Roboto, Arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        height: 48px;
        justify-content: center;
        letter-spacing: .25px;
        line-height: normal;
        max-width: 100%;
        overflow: visible;
        padding: 2px 24px;
        position: relative;
        text-align: center;
        text-transform: none;
        transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        width: auto;
        will-change: transform, opacity;
        z-index: 0;
    }

    .button-17:hover {
        background: #F6F9FE;
        color: #b71c1c;
    }

    .button-17:active {
        box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
        outline: none;
    }

    .button-17:focus {
        outline: none;
        border: 2px solid #c23e3e;
    }

    .button-17:not(:disabled) {
        box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
    }

    .button-17:not(:disabled):hover {
        box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
    }

    .button-17:not(:disabled):focus {
        box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
    }

    .button-17:not(:disabled):active {
        box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
    }

    .button-17:disabled {
        box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
    }

    .container {
        width: 100%;
        /*margin: 100px auto;*/
        justify-content: center;
    }

    .progressbar {
        counter-reset: step;
        padding-left: 0 !important;
    }

    .progressbar li {
        list-style-type: none;
        width: 25%;
        float: left;
        font-size: 12px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color: #7d7d7d;
    }

    .progressbar li:before {
        width: 30px;
        height: 30px;
        content: counter(step);
        counter-increment: step;
        line-height: 30px;
        border: 2px solid #7d7d7d;
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: white;
    }

    .progressbar li:after {
        width: 100%;
        height: 2px;
        content: '';
        position: absolute;
        background-color: #7d7d7d;
        top: 15px;
        left: -50%;
        z-index: -1;
    }

    .progressbar li:first-child:after {
        content: none;
    }

    .progressbar li.active {
        color: green;
    }

    .progressbar li.active:before {
        border-color: #55b776;
    }

    .progressbar li.active + li:after {
        background-color: #55b776;
    }

    img {
        max-width: 100%;
        display: block;
        object-fit: cover;
    }

    .card {
        display: flex;
        flex-direction: column;
        /*width: clamp(20rem, calc(20rem + 2vw), 22rem);*/
        width: 100%;
        overflow: hidden;
        box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
        border-radius: 1em;
        background: #ECE9E6;
        background: linear-gradient(to right, #FFFFFF, #ECE9E6);

    }

    .payment-card {
        display: flex;
        flex-direction: column;
        width: clamp(20rem, calc(20rem + 2vw), 22rem);
        width: 100%;
        overflow: hidden;
        box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
        border-radius: 1em;
        /*background: #ECE9E6;*/
        background: linear-gradient(to right, #FFFFFF, #FFFFFF);

    }


    .card__body {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    .tag {
        align-self: flex-start;
        padding: .25em .75em;
        border-radius: 1em;
        font-size: .75rem;
    }

    .tag + .tag {
        margin-left: .5em;
    }

    .tag-blue {
        background: #56CCF2;
        background: linear-gradient(to bottom, #2F80ED, #56CCF2);
        color: #fafafa;
    }

    .btn {
        padding: 15px;
        font-weight: 300;
        font-size: 0.8rem;
        text-decoration: none;
        text-align: center;
        transition: all .5s ease;
    }

    .btn--doar {
        color: #fff;
        padding-right: 0;
        background-color: #c0392b;
        -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 70%, 90% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 50%, 75% 100%, 0 100%);

    }

    .btn--doar:hover {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
    }

    .btn--doar:after {
        content: "\f004";
        color: #e74c3c;
        font-family: FontAwesome;
        display: inline-block;
        position: relative;
        right: -55px;
        transition: all 0.2s ease;
    }

    .btn--doar:hover:after {
        margin: -5px 15px;
        right: 0px;
    }

    .tag-red {
        background: #cb2d3e;
        background: linear-gradient(to bottom, #ef473a, #cb2d3e);
        color: #fafafa;
    }

    .main-container {
        /*height: 750px;*/
        /*background-color: #7a00ff;*/
        width: 100%;
        /*background: linear-gradient(30deg, #f43e4e 60%, #f65e6b 60%);;*/

    }

    .status-card {
        /*background: linear-gradient(60deg, #fddfe1 60%, #ffffff 60%);;*/
        /*background: linear-gradient(to bottom, #ef473a, #cb2d3e);*/

    }

    .span-center {
        display: flex;
        justify-content: center;
    }

    .both-accepted-container {
        /*height: 600px;*/
        color: black;
    }

    .semi-background {
        background-color: #F0F0F0;
    }
</style>

